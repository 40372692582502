<template>
  <div>
    <!-- Sticky Button -->
    <button class="sticky-button" @click="togglePopup">
      📏 Tegel Calculator
    </button>

    <!-- Popup Calculator -->
    <div v-if="showPopup" class="popup-overlay">
      <div class="popup-content">
        <button class="close-btn" @click="togglePopup">X</button>
        <h2>Tegel Calculator</h2>

        <form @submit.prevent="calculateTiles">
          <label for="area">Oppervlakte (m²):</label>
          <input type="number" v-model.number="area" required />

          <label for="wastage">
            Snijverlies (%):
            <button type="button" class="info-btn" @click="toggleInfo">
              ℹ️
            </button>
          </label>
          <input
            type="number"
            v-model.number="wastage"
            placeholder="Bijv. 5 of 10"
            required
          />

          <div v-if="showInfo" class="info-tooltip">
            <ul>
              <li>
                <b>Keuken:</b> Voor het betegelen van muren, zoals in de keuken,
                is het belangrijk om rekening te houden met snijverlies vanwege
                hoeken, uitsparingen en snijwerk rondom kasten en stopcontacten.
                <b>Snijverlies</b>: 5% - 10% (afhankelijk van het patroon en het
                aantal hoeken)
              </li>
              <li>
                <b>Buiten ruimtes:</b> Voor buitenruimtes is het snijverlies
                vaak iets hoger vanwege grotere tegels en onregelmatigheden in
                de ondergrond. <b>Snijverlies</b>: 10% (meer snijwerk door
                hoeken, afwatering en plaatsing)
              </li>
              <li>
                <b>Grote rechte ruimtes:</b> Bij het betegelen van grote, rechte
                ruimtes zoals woonkamers is het snijverlies meestal beperkt.
                <b>Snijverlies</b>: 5% - 8% (afhankelijk van het legpatroon)
              </li>
            </ul>
          </div>

          <button type="submit">Bereken</button>
        </form>

        <div v-if="result">
          <h3>Benodigde Tegels:</h3>
          <p>{{ result }} m² inclusief snijverlies.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const showPopup = ref(false);
const area = ref(0);
const wastage = ref(10); // Default wastage percentage
const result = ref(null);
const showInfo = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const toggleInfo = () => {
  showInfo.value = !showInfo.value;
};

const calculateTiles = () => {
  result.value = (area.value * (1 + wastage.value / 100)).toFixed(2);
};
</script>

<style scoped>
/* Sticky Button */
.sticky-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #1b3976;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease-in-out;
  z-index: 1000;
}

.sticky-button:hover {
  background-color: #000;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

input[type="number"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

button[type="submit"] {
  background-color: #1b3976;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #000;
}

/* Info Button */
.info-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #1b3976;
  font-size: 1rem;
  margin-left: 5px;
}

/* Tooltip */
.info-tooltip {
  background: #f1f1f1;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: left;
}
</style>
