<template>
  <div class="cart-page">
    <h1>Winkelwagen</h1>

    <!-- Total Price -->
    <p class="total-price" v-if="cart.length > 0">
      Totale prijs: <strong>€{{ totalPrice.toFixed(2) }}</strong>
    </p>

    <div v-if="cart.length === 0" class="empty-cart">
      <p>Uw winkelwagen is leeg.</p>
    </div>

    <div class="total-cart" v-else>
      <div class="cart-item" v-for="(item, index) in cart" :key="index">
        <!-- Card Layout -->
        <div class="card shadow-sm d-flex flex-row align-items-center">
          <!-- Left: Product Image -->
          <img
            class="product-image"
            :src="item.product.imageUrls[0]"
            alt="Product image"
          />

          <!-- Middle: Product Details -->
          <div class="product-details flex-grow-1 px-3">
            <h5 class="mb-1">{{ item.product.name }}</h5>
            <p class="mb-0">Prijs: €{{ item.product.price.toFixed(2) }}</p>
            <p v-if="item.product.discount" class="text-danger mb-0">
              Korting: {{ item.product.discount }}%
            </p>
            <p class="mt-2 mb-0">
              Subtotaal: €{{ (item.product.price * item.quantity).toFixed(2) }}
            </p>
          </div>

          <!-- Right: Quantity and Delete -->
          <div class="action-controls d-flex align-items-center">
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="decreaseQuantity(index)"
            >
              -
            </button>
            <span class="mx-2">{{ item.quantity }}</span>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="increaseQuantity(index)"
            >
              +
            </button>
            <button
              class="btn btn-danger btn-sm ms-2"
              @click="removeFromCartFunction(index)"
            >
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Quote Button -->
      <button
        type="button"
        class="btn btn-primary mt-3"
        data-bs-toggle="modal"
        data-bs-target="#quoteModal"
      >
        Offerte aanvragen
      </button>
    </div>

    <!-- Bootstrap Modal (same as before) -->
    <div
      class="modal fade"
      id="quoteModal"
      tabindex="-1"
      aria-labelledby="quoteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="quoteModalLabel">Offerte Aanvragen</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitQuote">
              <div class="mb-3">
                <label for="name" class="form-label">Naam</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="contact.name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="contact.email"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">Telefoonnummer</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phone"
                  v-model="contact.phone"
                  @input="validatePhoneNumber"
                  :class="{
                    'is-invalid': !isPhoneNumberValid && contact.phone,
                  }"
                  required
                />
                <div
                  v-if="!isPhoneNumberValid && contact.phone"
                  class="invalid-feedback"
                >
                  Ongeldig telefoonnummer. Zorg ervoor dat het begint met 06,
                  +316, of 00316.
                </div>
              </div>
              <div class="mb-3">
                <label for="remarks" class="form-label">Opmerkingen</label>
                <textarea
                  id="message"
                  class="form-control"
                  v-model="contact.message"
                  rows="3"
                ></textarea>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuleren
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="loading"
                >
                  <span v-if="loading">
                    <i class="fas fa-spinner fa-spin"></i> Versturen...
                  </span>
                  <span v-else>Verstuur</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner-container">
        <i class="fas fa-spinner fa-spin"></i>
        <p>Bezig met verzenden...</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed } from "vue";
import { useRouter } from "vue-router";
import { getCart, removeFromCart, clearCart } from "@/store/useCart"; // Centralized cart composable
import axios from "axios";

const router = useRouter();
const cart = ref(getCart());
const contact = ref({ name: "", email: "", phone: "", message: "" });

const isPhoneNumberValid = ref(true);
const loading = ref(false); // ✅ Added loading state

function validatePhoneNumber() {
  // Regular expression for Dutch phone numbers
  const phoneRegex =
    /^(06\d{8}|(\+31|0031)6\d{8}|(\+\d{1,3}|00\d{1,3})\d{4,14})$/;
  isPhoneNumberValid.value = phoneRegex.test(contact.value.phone);
}

// Watch for cart changes in localStorage
watchEffect(() => {
  cart.value = getCart();
});

// Computed total price
const totalPrice = computed(() =>
  cart.value.reduce((sum, item) => sum + item.product.price * item.quantity, 0)
);

// Remove from cart
function removeFromCartFunction(index) {
  removeFromCart(index);
  cart.value = getCart();
}

// Increase quantity
function increaseQuantity(index) {
  cart.value[index].quantity++;
  localStorage.setItem("cart", JSON.stringify(cart.value));
}

// Decrease quantity
function decreaseQuantity(index) {
  if (cart.value[index].quantity > 1) {
    cart.value[index].quantity--;
    localStorage.setItem("cart", JSON.stringify(cart.value));
  } else {
    removeFromCartFunction(index); // Remove if quantity becomes zero
  }
}

// Submit quote
async function submitQuote() {
  if (!isPhoneNumberValid.value) {
    alert("Voer een geldig telefoonnummer in.");
    return;
  }

  if (cart.value.length === 0) return alert("Uw winkelwagen is leeg.");

  loading.value = true; // ✅ Show loading spinner

  const requestData = {
    cart: cart.value,
    contact: contact.value,
  };

  const apiUrl = process.env.VUE_APP_API_URL;
  try {
    await axios.post(`${apiUrl}/api/send-quote`, requestData);

    clearCart();
    cart.value = [];
    document.querySelector("#quoteModal .btn-close").click(); // Close the modal programmatically
    router.push("/offerte-succes");
  } catch (error) {
    alert("Er is iets misgegaan bij het verzenden van uw offerte.");
  } finally {
    loading.value = false; // ✅ Hide loading spinner
  }
}
</script>

<style scoped>
.cart-page {
  padding: 20px;
}
.total-price {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.total-cart {
  display: flex;
  flex-direction: column; /* Stack cart-items vertically */
  align-items: center; /* Center items horizontally */
}

.cart-item {
  margin-bottom: 15px;
  width: 80%; /* Set a consistent width for all cart items */
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}
.product-details {
  flex-grow: 1;
}
.action-controls {
  display: flex;
  align-items: center;
}
.action-controls button {
  width: 32px;
  height: 32px;
  padding: 0;
  text-align: center;
}
.action-controls span {
  display: inline-block;
  min-width: 20px;
  text-align: center;
}
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 0.875rem;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}
.spinner-container {
  text-align: center;
  color: white;
}
</style>
