<template>
  <div class="update-product-page">
    <h1>Update Product</h1>

    <form @submit.prevent="updateTile">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="tile.name" />
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <input type="text" id="description" v-model="tile.description" />
      </div>

      <div class="form-group">
        <label for="articleNumber">Article Number:</label>
        <input
          type="number"
          id="articleNumber"
          v-model.number="tile.articleNumber"
        />
      </div>

      <div class="form-group">
        <label for="price">Price (€):</label>
        <input
          type="number"
          step="0.01"
          id="price"
          v-model.number="tile.price"
        />
      </div>

      <div class="form-group">
        <label for="discount">Discount (%):</label>
        <input
          type="number"
          step="0.01"
          id="discount"
          v-model.number="tile.discount"
        />
      </div>

      <div class="form-group">
        <label for="color">Color:</label>
        <input type="text" id="color" v-model="tile.color" />
      </div>

      <div class="form-group">
        <label for="brand">Brand:</label>
        <input type="text" id="brand" v-model="tile.brand" />
      </div>

      <div class="form-group">
        <label for="material">Material:</label>
        <input type="text" id="material" v-model="tile.material" />
      </div>

      <div class="form-group">
        <label for="executionType">Execution Type:</label>
        <input type="text" id="executionType" v-model="tile.executionType" />
      </div>

      <div class="form-group">
        <label for="applicationType">Application Type:</label>
        <input
          type="text"
          id="applicationType"
          v-model="tile.applicationType"
        />
      </div>

      <div class="form-group">
        <label for="length">Length (cm):</label>
        <input
          type="number"
          step="0.01"
          id="length"
          v-model.number="tile.length"
        />
      </div>

      <div class="form-group">
        <label for="width">Width (cm):</label>
        <input
          type="number"
          step="0.01"
          id="width"
          v-model.number="tile.width"
        />
      </div>

      <div class="form-group">
        <label for="thickness">Thickness (cm):</label>
        <input
          type="number"
          step="0.01"
          id="thickness"
          v-model.number="tile.thickness"
        />
      </div>

      <div class="form-group">
        <label for="weightPerTile">Weight per Tile (kg):</label>
        <input
          type="number"
          step="0.01"
          id="weightPerTile"
          v-model.number="tile.weightPerTile"
        />
      </div>

      <div class="form-group">
        <label for="m2PerPack">M² per Pack:</label>
        <input
          type="number"
          step="0.01"
          id="m2PerPack"
          v-model.number="tile.m2PerPack"
        />
      </div>

      <div class="form-group">
        <label for="tilesPerPack">Tiles per Pack:</label>
        <input
          type="number"
          step="0.01"
          id="tilesPerPack"
          v-model.number="tile.tilesPerPack"
        />
      </div>

      <div class="form-group">
        <label for="edgeType">Edge Type:</label>
        <input type="text" id="edgeType" v-model="tile.edgeType" />
      </div>

      <div class="form-group">
        <label for="eanCode">EAN Code:</label>
        <input type="text" id="eanCode" v-model="tile.eanCode" />
      </div>

      <div class="form-group">
        <label for="layInstructions">Lay Instructions:</label>
        <textarea
          id="layInstructions"
          v-model="tile.layInstructions"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="feature">Feature:</label>
        <textarea id="feature" v-model="tile.feature"></textarea>
      </div>

      <div class="form-group">
        <label for="maintenanceInstructions">Maintenance Instructions:</label>
        <textarea
          id="maintenanceInstructions"
          v-model="tile.maintenanceInstructions"
        ></textarea>
      </div>

      <!-- <div class="form-group">
        <label for="images">Upload Images:</label>
        <input type="file" id="images" multiple @change="handleFileUpload" />
      </div> -->

      <!-- Image URLs Input -->
      <div class="form-group">
        <label for="imageUrls">Image URLs (comma separated):</label>
        <input
          type="text"
          id="imageUrls"
          v-model="imageUrls"
          placeholder="Enter image URLs separated by commas"
        />
      </div>

      <button type="submit" class="btn btn-primary">Update Product</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "UpdateProductPage",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const tile = ref({
      name: "",
      description: "",
      articleNumber: null,
      price: null,
      discount: null,
      color: "",
      brand: "",
      material: "",
      executionType: "",
      applicationType: "",
      length: null,
      width: null,
      thickness: null,
      weightPerTile: null,
      m2PerPack: null,
      tilesPerPack: null,
      edgeType: "",
      eanCode: "",
      layInstructions: "",
      feature: "",
      maintenanceInstructions: "",
    });

    // const images = ref([]);

    const imageUrls = ref("");

    const fetchTileDetails = async () => {
      // try {
      //   const { id } = route.params; // Assumes `id` is passed in the route
      //   const response = await axios.get(`${apiUrl}/api/tiles/${id}`);
      //   tile.value = response.data;
      // } catch (error) {
      //   console.error("Error fetching tile details:", error);
      // }

      try {
        const { id } = route.params;
        const response = await axios.get(`${apiUrl}/api/tiles/${id}`);
        tile.value = response.data;

        // Fetch existing image URLs
        const imageResponse = await axios.get(
          `${apiUrl}/api/tiles/${id}/image-urls`
        );
        imageUrls.value = imageResponse.data.join(","); // Convert array to comma-separated string
      } catch (error) {
        console.error("Error fetching tile details:", error);
      }
    };

    // const handleFileUpload = (event) => {
    //   images.value = Array.from(event.target.files);
    // };

    const updateTile = async () => {
      try {
        const formData = new FormData();
        formData.append(
          "tile",
          new Blob([JSON.stringify(tile.value)], { type: "application/json" })
        );

        // images.value.forEach((image) => {
        //   formData.append("images", image);
        // });

        // Convert input string into an array and append
        const urls = imageUrls.value.split(",").map((url) => url.trim());
        urls.forEach((url) => {
          formData.append("imageUrls", url);
        });

        const { id } = route.params;
        await axios.put(`${apiUrl}/api/tiles/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("Product updated successfully!");
        router.push("/producten"); // Redirect to product list or desired page
      } catch (error) {
        console.error("Error updating product:", error);
        alert("Failed to update product.");
      }
    };

    onMounted(fetchTileDetails);

    return {
      tile,
      imageUrls,
      //handleFileUpload,
      updateTile,
    };
  },
};
</script>

<style scoped>
.create-product-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #369f73;
}
</style>
