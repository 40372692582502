<template>
  <div class="container my-5">
    <!-- Hero sectie met afbeelding links en tekst rechts -->
    <div class="hero-section">
      <div class="hero-image img-1"></div>
      <div class="hero-text">
        <h1>Hoogwaardige vloertegels voor elke stijl</h1>
        <p>
          Bij Panjsher B.V. vind je vloertegels van topkwaliteit voor elke
          woning en woonstijl. Van modern tot klassiek, onze tegels zijn
          duurzaam, onderhoudsvriendelijk en perfect voor elke ruimte. Ontdek
          ons assortiment en geef je interieur een stijlvolle uitstraling!
        </p>
      </div>
    </div>

    <!-- Bestseller sectie -->
    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Custom-made vloertegels uniek en op maat gemaakt</h2>
        <p>
          Heb je een specifiek patroon in gedachten, maar kun je het nergens
          vinden? Wij maken vloertegels op maat, precies zoals jij het wilt.
          Lever je ontwerp en gewenste afmetingen bij ons in, en wij realiseren
          het in hoogwaardige tegels. Uniek, persoonlijk en perfect passend bij
          jouw interieur!
        </p>
      </div>
      <div class="bestseller-image img-2"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-3"></div>
      <div class="hero-text">
        <h2>Vloertegels in alle materialen</h2>
        <p>
          Bij ons vind je vloertegels in elk denkbaar materiaal. Van keramiek en
          natuursteen tot betonlook en marmer, wij bieden voor iedere smaak en
          ruimte de perfecte oplossing. Kies voor kwaliteit en stijl met onze
          veelzijdige collectie!
        </p>
      </div>
    </div>

    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Vloertegels in alle afmetingen</h2>
        <p>
          Wij hebben we vloertegels in elke afmeting, van klein tot extra groot.
          Of je nu gaat voor een klassiek formaat zoals 30x30 cm, een moderne
          60x60 cm, of een royale 120x120 cm, wij hebben het allemaal. Ook
          langwerpige tegels zoals 30x60 cm en 80x160 cm zijn volop
          verkrijgbaar. Voor elke ruimte en stijl de perfecte tegel!
        </p>
      </div>
      <div class="bestseller-image img-4"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-5"></div>
      <div class="hero-text">
        <h2>Keramische tegels met een uniek karakter</h2>
        <p>
          Onze keramische tegels zijn niet alleen duurzaam en stijlvol, maar ook
          stuk voor stuk uniek. Dankzij een innovatief productieproces krijgt
          elke tegel een natuurlijke variatie in kleur en structuur. Dit zorgt
          voor een levendige en authentieke uitstraling, zonder in te leveren op
          kwaliteit en slijtvastheid. Perfect voor wie op zoek is naar een
          exclusieve en tijdloze vloer!
        </p>
      </div>
    </div>

    <div class="three-column-section scroll-section">
      <!-- Eerste kolom: Inspiratie -->
      <div class="column">
        <h2>Inspiratie nodig?</h2>
        <p>
          Begin je bouw- of renovatieplannen in onze showroom van 600m². Onze
          experten helpen je graag bij het kiezen van de juiste vloer.
        </p>
        <RouterLink class="cta-btn" to="/locatie"
          >Bezoek onze showroom</RouterLink
        >
      </div>

      <!-- Tweede kolom: Advies -->
      <div class="column">
        <h2>Advies nodig?</h2>
        <p>
          Twijfel je over de juiste vloer of tegel? Bel ons, vul ons contact
          formulier in, of kom langs in onze showroom!
        </p>
      </div>

      <!-- Derde kolom: Afbeelding -->
      <div class="column image-column"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const heroSection = document.querySelector(".hero-section");
    const sections = document.querySelectorAll(".scroll-section");

    // Ensure hero section is shown immediately after rendering
    if (heroSection) {
      requestAnimationFrame(() => {
        heroSection.classList.add("show");
      });
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    sections.forEach((section) => observer.observe(section));
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}

.scroll-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.scroll-section.show {
  opacity: 1;
  transform: translateY(0);
}

.container {
  background-color: #f1e9db;
  border-radius: 15px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.3em;
}
.assortment-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
.assortment-item {
  flex: 1 1 250px;
  max-width: 50%;
}
.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  height: 70vh !important;
  display: flex;
  align-items: flex-end;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  transition: transform 0.3s ease-in-out;
  padding: 0;
  text-decoration: none;
  border: 2px solid;
}
.card:hover {
  transform: translateY(-15px);
}
.card-body {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.card-text-container {
  text-align: center;
  width: 100%;
}
.card-title {
  font-weight: bold;
  font-size: 1.8em;
}
.card-text {
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.btn {
  display: block;
  width: fit-content;
  margin: 10px auto;
}

/* Responsiviteit voor tablets */
@media (max-width: 992px) {
  .assortment-item {
    flex: 1 1 calc(50% - 20px); /* Twee kolommen op tablets */
    max-width: calc(50% - 20px);
  }

  .card {
    height: 40vh;
  }
}

/* Responsiviteit voor mobiele schermen */
@media (max-width: 576px) {
  .assortment-item {
    flex: 1 1 90%; /* Eén kolom op mobiele schermen */
    max-width: 90%;
  }

  .card {
    height: 35vh;
  }
}

/* Hero sectie */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;

  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.hero-section.show {
  opacity: 1;
  transform: translateY(0);
}

/* Grote afbeelding aan de linkerkant */
.hero-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Tekst rechts */
.hero-text {
  flex: 1;
  text-align: left;
  padding: 20px;
}

/* Media Queries voor responsiviteit */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    order: 2;
    text-align: center;
  }

  .hero-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

.img-1 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/h567-q95/bestanden/artikelen/10/120793_2-01040017_The_Room_GRE_RO6_12_Lapatto_60x120_sfeer_(2).jpg?1728309175")
    no-repeat center center;
}

.img-2 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/17630_Casalgrande__Padana_-_Le_Ville_-_Hero.jpg?1728306872")
    no-repeat center center;
}

.img-3 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/h567-q95/bestanden/artikelen/10/130358_1-01033004_Tibur_Brown_60x120_sfeer.jpg?1728567586")
    no-repeat center center;
}

.img-4 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/h567-q95/bestanden/artikelen/10/127667_2-01060930_Pietre_di_Sardegna_Porto_Rotondo_30x60_sfeer.jpg?1728309168")
    no-repeat center center;
}

.img-5 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/h567-q95/bestanden/artikelen/10/139408_2-0067700_-_Pobles_Cadaques_Iris_18.5x18.5_sfeer.jpg?1730977283")
    no-repeat center center;
}

/* Bestseller sectie */
.bestseller-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;
}

/* Tekst links */
.bestseller-text {
  flex: 1;
  text-align: right;
  padding: 20px;
}

/* Afbeelding rechts */
.bestseller-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal inhoud */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  position: relative;
}

/* Sluitknop */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Scrollbare container */
.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  white-space: nowrap;
}

.scroll-item {
  flex: 0 0 auto;
  width: 200px;
  text-align: center;
}

.scroll-item img {
  width: 100%;
  border-radius: 5px;
}

/* Responsiviteit */
@media (max-width: 768px) {
  .bestseller-section {
    flex-direction: column;
    text-align: center;
  }

  .bestseller-text {
    order: 2;
    text-align: center;
  }

  .bestseller-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

/* Drie kolommen sectie */
.three-column-section {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

/* Algemene styling voor de kolommen */
.column {
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
/* Rechterkolom met afbeelding */
.image-column {
  background: url("https://www.navarti.com/wp-content/uploads/2023/05/amb-BARROW-Blanco-120x120-1-1184x888.jpg")
    no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  flex: 1;
}
/* Responsiviteit */
@media (max-width: 992px) {
  .three-column-section {
    flex-direction: column;
    text-align: center;
  }

  .image-column {
    height: 250px;
  }
}
</style>
