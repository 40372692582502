<template>
  <div class="product-page container">
    <!-- <h1 class="text-center my-4">Product Details</h1> -->

    <div v-if="loading" class="loading">Loading...</div>
    <div v-else>
      <!-- Main Content Row -->
      <div class="row mb-4">
        <!-- Image Carousel -->
        <div class="col-md-6">
          <div
            class="card card-carousel shadow-lg h-100 d-flex justify-content-center align-items-center"
          >
            <div
              v-if="product.imageUrls && product.imageUrls.length > 0"
              id="productImagesCarousel"
              class="carousel slide w-100"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div
                  v-for="(image, index) in combinedImages"
                  :key="index"
                  :class="['carousel-item', { active: index === 0 }]"
                >
                  <img
                    :src="image"
                    class="d-block w-100"
                    :alt="`Image ${index + 1} of ${product.name}`"
                  />
                </div>
              </div>
              <button
                v-if="product.imageUrls.length > 1"
                class="carousel-control-prev custom-control"
                type="button"
                data-bs-target="#productImagesCarousel"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon bg-black"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                v-if="product.imageUrls.length > 1"
                class="carousel-control-next custom-control"
                type="button"
                data-bs-target="#productImagesCarousel"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon bg-black"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>

        <!-- General Information -->
        <div class="col-md-6">
          <div class="card shadow-lg h-100">
            <div class="card-body text-start">
              <h2 class="card-title" v-if="product.name">{{ product.name }}</h2>
              <ul
                class="list-group my-3 alternate-list"
                style="border-radius: 15px"
              >
                <p class="price card-text fw-bold" v-if="product.price">
                  Prijs per m²: €{{ product.price.toFixed(2) }}
                </p>
                <li
                  class="card-text list-group-item"
                  style="
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                  "
                  v-if="product.color"
                >
                  Kleur: {{ product.color }}
                </li>
                <li class="card-text list-group-item" v-if="product.brand">
                  Merk: {{ product.brand }}
                </li>
                <li class="card-text list-group-item" v-if="product.material">
                  Materiaal: {{ product.material }}
                </li>
                <li
                  class="card-text list-group-item"
                  v-if="product.length && product.width"
                >
                  Afmetingen: {{ product.length }} x {{ product.width }} cm
                </li>
                <li
                  class="card-text list-group-item"
                  style="
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                  "
                  v-if="product.thickness"
                >
                  Dikte: {{ product.thickness }} cm
                </li>
                <div class="form-group my-3">
                  <label for="quantity">Aantal m²:</label>
                  <input
                    v-model="quantity"
                    type="number"
                    min="1"
                    id="quantity"
                    class="form-control"
                  />
                </div>
                <p class="total-price fw-bold">
                  Totale prijs: €{{ (product.price * quantity).toFixed(2) }}
                </p>
              </ul>
              <button @click="handleAddToCart" class="btn btn-primary w-100">
                Voeg toe aan winkelwagen
              </button>

              <!-- Snackbar -->
              <SnackbarComponent
                v-if="showSnackbar"
                :message="snackbarMessage"
                @dismiss="showSnackbar = false"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Tabs Section -->
      <div class="card shadow-lg">
        <div class="card-header">
          <ul
            class="nav nav-tabs card-header-tabs"
            id="productTabs"
            role="tablist"
          >
            <li class="nav-item" role="presentation" v-if="hasDescription">
              <button
                class="nav-link active"
                id="description-tab"
                data-bs-toggle="tab"
                data-bs-target="#description"
                type="button"
                role="tab"
                aria-controls="description"
                aria-selected="true"
              >
                Omschrijving
              </button>
            </li>
            <li class="nav-item" role="presentation" v-if="hasDetails">
              <button
                class="nav-link"
                id="details-tab"
                data-bs-toggle="tab"
                data-bs-target="#details"
                type="button"
                role="tab"
                aria-controls="details"
                aria-selected="true"
              >
                Details
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="product.layInstructions"
            >
              <button
                class="nav-link"
                id="lay-instructions-tab"
                data-bs-toggle="tab"
                data-bs-target="#lay-instructions"
                type="button"
                role="tab"
                aria-controls="lay-instructions"
                aria-selected="false"
              >
                Leginstructies
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-if="product.maintenanceInstructions"
            >
              <button
                class="nav-link"
                id="maintenance-tab"
                data-bs-toggle="tab"
                data-bs-target="#maintenance"
                type="button"
                role="tab"
                aria-controls="maintenance"
                aria-selected="false"
              >
                Onderhoudsinstructies
              </button>
            </li>
            <li class="nav-item" role="presentation" v-if="product.feature">
              <button
                class="nav-link"
                id="features-tab"
                data-bs-toggle="tab"
                data-bs-target="#features"
                type="button"
                role="tab"
                aria-controls="features"
                aria-selected="false"
              >
                Kenmerken
              </button>
            </li>
          </ul>
        </div>
        <div class="card-body text-start">
          <div class="tab-content" id="productTabsContent">
            <!-- Description Tab -->
            <div
              class="tab-pane fade"
              id="description"
              role="tabpanel"
              aria-labelledby="description-tab"
              v-if="product.description"
            >
              <p class="my-3">{{ product.description }}</p>
            </div>

            <!-- Details Tab -->
            <div
              class="tab-pane fade show active"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
              v-if="hasDetails"
            >
              <ul class="list-group my-3 alternate-list">
                <li class="list-group-item" v-if="product.weightPerTile">
                  Gewicht per tegel: {{ product.weightPerTile }} kg
                </li>
                <li class="list-group-item" v-if="product.m2PerPack">
                  m² per verpakking: {{ product.m2PerPack }}
                </li>
                <li class="list-group-item" v-if="product.tilesPerPack">
                  Tegels per verpakking: {{ product.tilesPerPack }}
                </li>
                <li class="list-group-item" v-if="product.edgeType">
                  Randtype: {{ product.edgeType }}
                </li>
                <li class="list-group-item" v-if="product.applicationType">
                  Toepassing: {{ product.applicationType }}
                </li>
                <li class="list-group-item" v-if="product.executionType">
                  Afwerking: {{ product.executionType }}
                </li>
                <li class="list-group-item" v-if="product.eanCode">
                  EAN Code: {{ product.eanCode }}
                </li>
                <li class="list-group-item" v-if="product.articleNumber">
                  Artikelnr.: {{ product.articleNumber }}
                </li>
              </ul>
            </div>

            <!-- Lay Instructions Tab -->
            <div
              class="tab-pane fade"
              id="lay-instructions"
              role="tabpanel"
              aria-labelledby="lay-instructions-tab"
              v-if="product.layInstructions"
            >
              <p class="my-3">{{ product.layInstructions }}</p>
            </div>

            <!-- Maintenance Tab -->
            <div
              class="tab-pane fade"
              id="maintenance"
              role="tabpanel"
              aria-labelledby="maintenance-tab"
              v-if="product.maintenanceInstructions"
            >
              <p class="my-3">{{ product.maintenanceInstructions }}</p>
            </div>

            <!-- Features Tab -->
            <div
              class="tab-pane fade"
              id="features"
              role="tabpanel"
              aria-labelledby="features-tab"
              v-if="product.feature"
            >
              <ul class="list-group my-3 alternate-list">
                <li
                  class="list-group-item"
                  v-for="(feature, index) in parseFeatures(product.feature)"
                  :key="index"
                >
                  {{ feature }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { addToCart } from "@/store/useCart";
import SnackbarComponent from "@/components/SnackbarComponent.vue";

const combinedImages = computed(() => {
  // const base64Images = product.value?.base64Images
  //   ? product.value.base64Images.map((img) => `data:image/jpeg;base64,${img}`)
  //   : [];

  // const imageUrls = product.value?.imageUrls || [];

  // if (product.value.id > 183) {
  const imageUrls = product.value?.imageUrls || [];
  if (imageUrls.length > 1) {
    return [...imageUrls];
  } else {
    return imageUrls;
  }
  // } else {
  //   const base64Images = product.value?.base64Images
  //     ? product.value.base64Images.map((img) => `data:image/jpeg;base64,${img}`)
  //     : [];
  //   return [...base64Images];
  // }

  //return [...base64Images, ...imageUrls].filter(Boolean); // ✅ Filters out undefined or null values
});

const route = useRoute();
const product = ref(null);
const loading = ref(true);
const quantity = ref(1);
const apiUrl = process.env.VUE_APP_API_URL;

const hasDescription = ref(false);
const hasDetails = ref(false);
const showSnackbar = ref(false);
const snackbarMessage = ref("");

async function fetchProductDetails(id) {
  try {
    // Fetch product details
    const response = await axios.get(`${apiUrl}/api/tiles/${id}`);
    product.value = response.data;
    product.value.id = id;

    hasDescription.value = product.value.description;

    // Determine if there are details to show
    hasDetails.value =
      product.value.weightPerTile ||
      product.value.m2PerPack ||
      product.value.tilesPerPack ||
      product.value.edgeType ||
      product.value.applicationType ||
      product.value.executionType ||
      product.value.eanCode ||
      product.value.articleNumber;

    // ✅ Image fetching based on ID
    // if (id > 183) {
    const imageResponse = await axios.get(
      `${apiUrl}/api/tiles/${id}/image-urls`
    );
    product.value.imageUrls = imageResponse.data; // This should be a list of URLs
    // } else {
    //   const imageResponse = await axios.get(`${apiUrl}/api/tiles/${id}/images`);
    //   product.value.base64Images = imageResponse.data; // List of Base64 images
    //   console.log(imageResponse.data);
    // }
  } catch (error) {
    console.error("Error fetching product details:", error);
  } finally {
    loading.value = false;
  }
}

// On mount, fetch the product using the ID from the route
onMounted(() => {
  const productId = route.params.id;
  if (productId) {
    fetchProductDetails(productId);
  }
});

function handleAddToCart() {
  addToCart(product.value, quantity.value);
  snackbarMessage.value = "Product is toegevoegd aan de winkelwagen.";
  showSnackbar.value = true;

  // Auto-hide snackbar after 3 seconds
  setTimeout(() => {
    showSnackbar.value = false;
  }, 3000);
}

function parseFeatures(features) {
  if (!features) return [];
  return features.split("\n").map((feature) => feature.trim());
}
</script>

<style scoped>
.product-page {
  padding: 20px;
}

.product-details {
  margin-bottom: 30px;
}

.carousel-inner img {
  max-height: 400px;
  object-fit: contain;
}

.card-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-tabs .nav-link {
  font-weight: bold;
}

.tab-content {
  text-align: left;
}

.list-group-item {
  font-size: 1rem;
}

.tab-pane {
  border: none;
}

ul.alternate-list {
  list-style-type: none; /* Removes bullets */
  margin: 0;
  padding: 0;
  border: none;
}

ul.alternate-list li {
  padding: 10px;
  border: none; /* Removes borders */
}

ul.alternate-list li:nth-child(odd) {
  background-color: #e4e2dd75; /* Light gray */
}

ul.alternate-list li:nth-child(even) {
  background-color: #9a949461; /* White */
}
</style>
