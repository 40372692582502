<template>
  <div class="create-product-page">
    <h1>Create New Product</h1>

    <form @submit.prevent="submitTile">
      <div class="form-group" v-for="(field, key) in tile" :key="key">
        <label :for="key">{{ formatLabel(key) }}:</label>
        <input
          v-if="
            typeof field !== 'string' ||
            (key !== 'layInstructions' &&
              key !== 'feature' &&
              key !== 'maintenanceInstructions')
          "
          :type="typeof field === 'number' ? 'number' : 'text'"
          :id="key"
          v-model="tile[key]"
          :step="typeof field === 'number' ? '0.01' : undefined"
        />
        <textarea v-else :id="key" v-model="tile[key]"> </textarea>
      </div>

      <div class="form-group">
        <label for="imageUrls">Image URLs (comma separated):</label>
        <input
          type="text"
          id="imageUrls"
          v-model="imageUrls"
          placeholder="Enter image URLs separated by commas"
        />
      </div>

      <button type="submit" class="btn btn-primary">Create Product</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
const apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: "CreateProductPage",
  setup() {
    const tile = ref({
      name: "",
      description: "",
      articleNumber: null,
      price: null,
      discount: null,
      color: "",
      brand: "",
      material: "",
      executionType: "",
      applicationType: "",
      length: null,
      width: null,
      thickness: null,
      weightPerTile: null,
      m2PerPack: null,
      tilesPerPack: null,
      edgeType: "",
      eanCode: "",
      layInstructions: "",
      feature: "",
      maintenanceInstructions: "",
    });

    const imageUrls = ref("");

    const submitTile = async () => {
      try {
        const formData = new FormData();
        formData.append(
          "tile",
          new Blob([JSON.stringify(tile.value)], { type: "application/json" })
        );

        //let urls = [];
        const urls = imageUrls.value.split(",").map((url) => url.trim());

        urls.forEach((url) => {
          formData.append("imageUrls", url);
        });

        //formData.append("imageUrls", JSON.stringify(urls));

        const response = await axios.post(`${apiUrl}/api/tiles`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Product created:", response);
        alert("Product created successfully!");
      } catch (error) {
        console.error("Error creating product:", error);
        alert("Failed to create product.");
      }
    };

    const formatLabel = (key) => {
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    };

    return {
      tile,
      imageUrls,
      submitTile,
      formatLabel,
    };
  },
};
</script>

<style scoped>
.create-product-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 10px 20px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #369f73;
}
</style>
