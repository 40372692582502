<template>
  <div class="location-page">
    <h1>Bezoek Panjsher Tegels - Route en Openingstijden</h1>

    <div class="info-container">
      <!-- Openingstijden -->
      <div class="info-box">
        <h2>🕒 Openingstijden</h2>
        <ul>
          <li :class="{ highlight: today === 'maandag' }">
            <strong>Maandag:</strong> Gesloten
          </li>
          <li :class="{ highlight: today === 'dinsdag' }">
            <strong>Dinsdag:</strong> 10:00 - 17:00
          </li>
          <li :class="{ highlight: today === 'woensdag' }">
            <strong>Woensdag:</strong> 10:00 - 17:00
          </li>
          <li :class="{ highlight: today === 'donderdag' }">
            <strong>Donderdag:</strong> 10:00 - 17:00
          </li>
          <li :class="{ highlight: today === 'vrijdag' }">
            <strong>Vrijdag:</strong> 10:00 - 17:00
          </li>
          <li :class="{ highlight: today === 'zaterdag' }">
            <strong>Zaterdag:</strong> 10:00 - 17:00
          </li>
          <li :class="{ highlight: today === 'zondag' }">
            <strong>Zondag:</strong> 11:00 - 16:00
          </li>
        </ul>
      </div>

      <!-- Parkeerinformatie -->
      <div class="info-box">
        <h2>🅿️ Parkeren</h2>
        <p>
          Ons terrein heeft voldoende gratis parkeergelegenheid voor bezoekers.
          Je kunt eenvoudig en snel parkeren direct bij de showroom.
        </p>

        <div class="address">
          <p>
            <strong>📍 Adres:</strong> Steenweg op Mol 187, 2360 Oud-Turnhout,
            België
          </p>
        </div>
        <p class="no-appointment">
          📌 Geen afspraak nodig voor het bezichtigen van de showroom!
        </p>
      </div>
    </div>

    <!-- Adres -->

    <!-- Google Maps -->
    <div class="map-container">
      <iframe
        :src="mapSrc"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const mapSrc =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.291744852402!2d5.003796776114648!3d51.305756625414645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b412875ba2b3%3A0xd1900719dd4c4989!2sSteenweg%20op%20Mol%20187%2C%202360%20Oud-Turnhout%2C%20Belgi%C3%AB!5e0!3m2!1snl!2snl!4v1723308983042!5m2!1snl!2snl";

// Huidige dag ophalen en highlighten
const days = [
  "zondag",
  "maandag",
  "dinsdag",
  "woensdag",
  "donderdag",
  "vrijdag",
  "zaterdag",
];
const today = computed(() => days[new Date().getDay()]);
</script>

<style scoped>
.location-page {
  padding: 20px;
  text-align: center;
}

/* Flex container voor de openingstijden en parkeerinformatie */
.info-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Stijl voor de informatieblokken */
.info-box {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  flex: 1;
  max-width: 600px; /* Even breed als de map */
}

/* Opmaak openingstijden */
.info-box ul {
  list-style: none;
  padding: 0;
}

.info-box li {
  padding: 5px 0;
}

/* Huidige dag highlighten */
.highlight {
  font-weight: bold;
  color: #42b983;
}

/* Geen afspraak melding */
.no-appointment {
  margin-top: 120px;
  font-weight: bold;
  color: #d9534f;
}

/* Adres */
.address {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Map styling */
.map-container {
  display: flex;
  justify-content: center;
}

iframe {
  width: 100%;
  max-width: 1200px;
  height: 450px;
  border: none;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .info-container {
    flex-direction: column;
    align-items: center;
  }

  .info-box {
    max-width: 100%;
    width: 90%;
  }
}
</style>
