<template>
  <div class="container my-5">
    <!-- Hero sectie met afbeelding links en tekst rechts -->
    <div class="hero-section">
      <div class="hero-image"></div>
      <div class="hero-text">
        <h1>Stijlvolle tegels</h1>
        <p>
          Panjsher tegels biedt een breed scala aan hoogwaardige vloertegels en
          wandtegels, perfect voor een duurzame en elegante afwerking.
        </p>
      </div>
    </div>

    <h2 class="mt-5 scroll-section">Topmerken voor elke ruimte</h2>
    <p class="scroll-section">
      Panjsher werkt met de bekendste merken en biedt kwaliteitsvolle tegels
      voor elke ruimte en elk budget.
    </p>

    <div class="assortment-grid scroll-section">
      <div
        v-for="(item, index) in assortment"
        :key="index"
        class="assortment-item"
      >
        <a
          :href="item.link"
          class="card h-100"
          :style="{ backgroundImage: 'url(' + item.image + ')' }"
        >
          <div class="card-body">
            <div class="card-text-container">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">{{ item.description }}</p>
            </div>
          </div>
        </a>
      </div>
    </div>

    <!-- Bestseller sectie -->
    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Ontdek onze bestsellers</h2>
        <p>
          Benieuwd naar onze populairste tegels? Bekijk de best verkochte
          stijlen en vind de perfecte match voor uw ruimte!
        </p>
        <button class="cta-btn" @click="showModal = true">Bestsellers</button>
      </div>
      <div class="bestseller-image"></div>
    </div>

    <!-- Modal voor Bestsellers -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <button class="close-btn" @click="showModal = false">&times;</button>
        <h3 class="text-center">Onze bestsellers</h3>
        <!-- Slider Container -->
        <div class="slider-container">
          <button class="slider-btn left" @click="prevSlide">&#10094;</button>

          <div class="slide">
            <img
              :src="currentSlide.image"
              :alt="currentSlide.title"
              class="slide-image"
            />
            <p class="slide-title">{{ currentSlide.title }}</p>
          </div>

          <button class="slider-btn right" @click="nextSlide">&#10095;</button>
        </div>
      </div>
    </div>

    <div class="three-column-section scroll-section">
      <!-- Eerste kolom: Inspiratie -->
      <div class="column">
        <h2>Inspiratie nodig?</h2>
        <p>
          Begin je bouw- of renovatieplannen in onze showroom van 600m². Onze
          experten helpen je graag bij het kiezen van de juiste vloer.
        </p>
        <RouterLink class="cta-btn" to="/locatie"
          >Bezoek onze showroom</RouterLink
        >
      </div>

      <!-- Tweede kolom: Advies -->
      <div class="column">
        <h2>Advies nodig?</h2>
        <p>
          Twijfel je over de juiste vloer of tegel? Bel ons, vul ons contact
          formulier in, of kom langs in onze showroom!
        </p>
      </div>

      <!-- Derde kolom: Afbeelding -->
      <div class="column image-column"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const showModal = ref(false); // Set to false initially
const currentIndex = ref(0);

// Compute current slide
const currentSlide = computed(() => bestsellers.value[currentIndex.value]);

// Next Slide
const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % bestsellers.value.length;
};

// Previous Slide
const prevSlide = () => {
  currentIndex.value =
    (currentIndex.value - 1 + bestsellers.value.length) %
    bestsellers.value.length;
};

const assortment = ref([
  {
    title: "Vloertegels",
    description: "Keramische vloertegels in elke stijl.",
    image:
      "https://www.navarti.com/wp-content/uploads/2022/06/A-AERIS-75X150_AJUSTE-1184x888.jpg",
    link: "/vloertegels",
  },
  {
    title: "Wandtegels",
    description: "Kwaliteitsvolle wandtegels voor een moderne uitstraling.",
    image:
      "https://www.italgranitigroup.com/images/marmi-di-impronta/immagine-quadrata-marmi-impronta.jpg",
    link: "/wandtegels",
  },
  {
    title: "Keramisch parket",
    description: "De warmte van hout met het gemak van keramiek.",
    image:
      "https://www.italgranitigroup.com/cache/jw_sigpro/jwsigpro_cache_26bbc38975_01_entrance.jpg",
    link: "/keramischetegels",
  },
  {
    title: "Terrastegels",
    description: "Duurzame vloeren voor jouw tuin of terras.",
    image:
      "https://www.marazzitile.co.uk/app/uploads/collezioni/fabula20/marazzi_fabula20_001.jpg",
    link: "/terrastegels",
  },
]);
const bestsellers = ref([
  {
    title: "Importa Limestone",
    image:
      "https://www.italgranitigroup.com/media/k2/items/cache/331cc23b99ba1f3968c8e515604da6be_XL.jpg",
  },
  {
    title: "Trendy Chic",
    image:
      "https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/91812_Sintesi_-_Salt_Stone_-_Hero.jpg?1728306899",
  },
  {
    title: "Impronta Concrete",
    image:
      "https://www.italgranitigroup.com/media/k2/items/cache/205bca721172719462a4e1ef692283f7_XL.jpg",
  },
  {
    title: "Italgraniti I Cementi",
    image:
      "https://www.italgranitigroup.com/media/k2/items/cache/a7869d52404fd702fc548a5b18a8197d_XL.jpg",
  },
  {
    title: "Margres Evolution - White",
    image: "https://margres.com/media/evolution-white-60x120.jpg",
  },

  {
    title: "Margres Evolution - Light Grey",
    image:
      "https://margres.com/media/Evolution-Light-Grey-60x120-NR---90x90-E-ASR.jpg",
  },
  {
    title: "Margres Evolution - Black",
    image: "https://margres.com/media/Evolution-Black-120x120-NR.jpg",
  },
  {
    title: "Margres Evolution - Beige",
    image: " https://margres.com/media/Beige-90x90-ASR---90x90-NR.jpg",
  },
  {
    title: "Margres Purestone - Grey",
    image: "https://margres.com/media/GREY-120X120-NR.jpg",
  },
  {
    title: "Margres Purestone - Linea White Grey",
    image:
      "https://margres.com/media/pure-stone-margres-linea-white-grey-613797e44712c.jpg",
  },
  {
    title: "Margres Purestone - Beige",
    image:
      "https://margres.com/media/pure-stone-margres-beige-61379a6a93adb.jpg",
  },
  {
    title: "Margres Purestone - Mosaic Circles",
    image:
      "https://margres.com/media/pure-stone-margres-mosaic-circles-614deb2e7a926.jpg",
  },
  {
    title: "Margres Purestone - Light Grey",
    image:
      "https://margres.com/media/LIGHT-GREY-PS3-60X120-A---60X60-A---LISTEL-BRUSHED-COPPER-1X120.jpg",
  },
  {
    title: "Margres Purestone - White",
    image: "https://margres.com/media/WHITE-120X120-NR-pure-stone.jpg",
  },
  {
    title: "Fioranese",
    image:
      "https://www.fioranese.it/wp-content/uploads/2025/01/Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg",
  },
  {
    title: "Fioranese - Ciottoli Sassi Avorio",
    image:
      "Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg (1024×724)",
  },
  {
    title: "Fioranese - Sassi Avorio",
    image:
      "Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg (1024×724)",
  },
  {
    title: "Fioranese - Ciottoli Sabbia Beige Grigio",
    image:
      "Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg (1024×724)",
  },
  {
    title: "Kronos Le Reverse",
    image:
      "https://kronosceramiche.nl/src/uploads/sites/6/2019/10/Reverse-A.jpg",
  },
  {
    title: "Kronos Le Reverse - Bagno Effetto Pietra Chiaro",
    image:
      "https://kronosceramiche.nl/src/uploads/sites/6/2019/12/bagno-effetto-pietra-chiaro-1-600x600.jpg",
  },
  {
    title: "Kronos Le Reverse",
    image:
      "Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg (1024×724)",
  },
  {
    title: "Kronos Le Reverse",
    image:
      "Pavimenti-in-gres-porcellanato_Ceramica-Fioranese_I-Ciottoli_Sassi-Argilla-906x906-1.jpg (1024×724)",
  },
]);

onMounted(() => {
  const heroSection = document.querySelector(".hero-section");
  const sections = document.querySelectorAll(".scroll-section");

  // Ensure hero section is shown immediately after rendering
  if (heroSection) {
    requestAnimationFrame(() => {
      heroSection.classList.add("show");
    });
  }

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      threshold: 0.2,
      rootMargin: "0px 0px -100px 0px",
    }
  );

  sections.forEach((section) => observer.observe(section));
});
</script>

<style scoped>
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}

.scroll-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.scroll-section.show {
  opacity: 1;
  transform: translateY(0);
}

.container {
  background-color: #f1e9db;
  border-radius: 15px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.3em;
}
.assortment-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
.assortment-item {
  flex: 1 1 250px;
  max-width: 50%;
}
.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  height: 70vh !important;
  display: flex;
  align-items: flex-end;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  transition: transform 0.3s ease-in-out;
  padding: 0;
  text-decoration: none;
  border: 2px solid;
}
.card:hover {
  transform: translateY(-15px);
}
.card-body {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.card-text-container {
  text-align: center;
  width: 100%;
}
.card-title {
  font-weight: bold;
  font-size: 1.8em;
}
.card-text {
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  font-size: 1em;
}
.btn {
  display: block;
  width: fit-content;
  margin: 10px auto;
}

/* Responsiviteit voor tablets */
@media (max-width: 992px) {
  .assortment-item {
    flex: 1 1 calc(50% - 20px); /* Twee kolommen op tablets */
    max-width: calc(50% - 20px);
  }

  .card {
    height: 40vh;
  }
}

/* Responsiviteit voor mobiele schermen */
@media (max-width: 576px) {
  .assortment-item {
    flex: 1 1 90%; /* Eén kolom op mobiele schermen */
    max-width: 90%;
  }

  .card {
    height: 35vh;
  }
}

/* Hero sectie */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;

  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.hero-section.show {
  opacity: 1;
  transform: translateY(0);
}

/* Grote afbeelding aan de linkerkant */
.hero-image {
  flex: 1;
  background: url("https://lafaenzaceramica.com/wp-content/uploads/2024/09/LF_couture.jpg")
    no-repeat center center;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Tekst rechts */
.hero-text {
  flex: 1;
  text-align: left;
  padding: 20px;
}

/* Media Queries voor responsiviteit */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    order: 2;
    text-align: center;
  }

  .hero-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

/* Bestseller sectie */
.bestseller-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;
}

/* Tekst links */
.bestseller-text {
  flex: 1;
  text-align: right;
  padding: 20px;
}

/* Afbeelding rechts */
.bestseller-image {
  flex: 1;
  background: url("https://www.navarti.com/wp-content/uploads/2023/04/AMB_ALYSA-GRIS-45X90-copia-1243x888.jpg")
    no-repeat center center;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal inhoud */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  position: relative;
}

/* Sluitknop */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Slider Container */
.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 80vh;
}

/* Slide Image */
.slide-image {
  width: 100%;
  max-height: 70vh;
  object-fit: cover;
  border-radius: 10px;
}

/* Slide Title */
.slide-title {
  font-size: 1.5em;
  margin-top: 10px;
}

/* Slider Buttons */
.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background: rgba(0, 0, 0, 0.5); */
  /* color: white; */
  border: none;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  /* border-radius: 50%; */
  transition: 0.3s;
}

.slider-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Left and Right Button Positions */
.slider-btn.left {
  left: 10px;
}

.slider-btn.right {
  right: 10px;
}

/* Scrollbare container */
.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  white-space: nowrap;
}

.scroll-item {
  flex: 0 0 auto;
  width: 200px;
  text-align: center;
}

.scroll-item img {
  width: 100%;
  border-radius: 5px;
}

/* Responsiviteit */
@media (max-width: 768px) {
  .bestseller-section {
    flex-direction: column;
    text-align: center;
  }

  .bestseller-text {
    order: 2;
    text-align: center;
  }

  .bestseller-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

/* Drie kolommen sectie */
.three-column-section {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

/* Algemene styling voor de kolommen */
.column {
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
/* Rechterkolom met afbeelding */
.image-column {
  background: url("https://www.navarti.com/wp-content/uploads/2023/05/amb-BARROW-Blanco-120x120-1-1184x888.jpg")
    no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  flex: 1;
}
/* Responsiviteit */
@media (max-width: 992px) {
  .three-column-section {
    flex-direction: column;
    text-align: center;
  }

  .image-column {
    height: 250px;
  }
}
</style>
