<template>
  <div class="product-page container-fluid">
    <CalculatorComponent></CalculatorComponent>

    <!-- Loading Screen -->
    <div v-if="loading" class="loading-screen">
      <div class="spinner">
        <i class="fa fa-spinner fa-spin"></i>
        <p>Producten laden...</p>
      </div>
    </div>

    <!-- Search Component -->
    <div class="row mb-4">
      <div class="col-12 complete-search-bar">
        <div class="search-bar p-3 shadow-sm row">
          <div class="col-1" style="align-content: center">
            <i class="fa fa-search"> </i>
          </div>
          <div class="col-11">
            <input
              type="text"
              v-model="searchQuery"
              @input="liveSearch"
              placeholder="Zoek product..."
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Sidebar for Filters -->
      <div class="col-md-3 col-12 mb-4">
        <!-- Collapsible Filter Sidebar -->
        <button
          class="btn btn-link d-md-none mb-3"
          type="button"
          @click="toggleFilterCollapse"
        >
          <i class="fa fa-filter"></i>
          {{ isFilterCollapsed ? "Filters Tonen" : "Filters Verbergen" }}
        </button>
        <div :class="{ collapse: isFilterCollapsed && isSmallScreen }">
          <div class="card p-3 shadow-sm">
            <!-- Brand Filter -->
            <div class="mb-1 filter-row">
              <label for="brand" class="form-label">Merk</label>
              <select
                v-model="selectedBrand"
                @change="fetchFilteredTiles"
                id="brand"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Merk kiezen...
                </option>
                <option value="">Alle</option>
                <option v-for="brand in brands" :key="brand" :value="brand">
                  {{ brand }}
                </option>
              </select>
            </div>

            <!-- Dimensions Filter -->
            <div class="mb-1 filter-row">
              <label for="dimensions" class="form-label">Afmetingen (cm)</label>
              <select
                v-model="selectedDimensions"
                @change="fetchFilteredTiles"
                id="dimensions"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Dimensies kiezen...
                </option>
                <option value="">Alle</option>
                <option
                  v-for="dimension in dimensions"
                  :key="dimension"
                  :value="dimension"
                >
                  {{ dimension }}
                </option>
              </select>
            </div>

            <!-- Color Filter -->
            <div class="mb-1 filter-row">
              <label for="color" class="form-label">Kleur</label>
              <select
                v-model="selectedColor"
                @change="fetchFilteredTiles"
                id="color"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Kleur kiezen...
                </option>
                <option value="">Alle</option>
                <option v-for="color in colors" :key="color" :value="color">
                  {{ color }}
                </option>
              </select>
            </div>

            <!-- Thickness Filter -->
            <div class="mb-1 filter-row">
              <label for="thickness" class="form-label">Dikte (cm)</label>
              <select
                v-model="selectedThickness"
                @change="fetchFilteredTiles"
                id="thickness"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Dikte kiezen...
                </option>
                <option value="">Alle</option>
                <option
                  v-for="thickness in thicknesses"
                  :key="thickness"
                  :value="thickness"
                >
                  {{ thickness }}
                </option>
              </select>
            </div>

            <!-- Material Filter -->
            <div class="mb-1 filter-row">
              <label for="material" class="form-label">Materiaal</label>
              <select
                v-model="selectedMaterial"
                @change="fetchFilteredTiles"
                id="material"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Materiaal kiezen...
                </option>
                <option value="">Alle</option>
                <option
                  v-for="material in materials"
                  :key="material"
                  :value="material"
                >
                  {{ material }}
                </option>
              </select>
            </div>

            <!-- ApplicationType Filter -->
            <div class="mb-1 filter-row">
              <label for="applicationType" class="form-label">Toepassing</label>
              <select
                v-model="selectedApplicationType"
                @change="fetchFilteredTiles"
                id="applicationType"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Toepassing kiezen...
                </option>
                <option value="">Alle</option>
                <option
                  v-for="type in applicationTypes"
                  :key="type"
                  :value="type"
                >
                  {{ type }}
                </option>
              </select>
            </div>

            <!-- EdgeType Filter -->
            <div class="mb-1 filter-row">
              <label for="edgeType" class="form-label">Hoekafwerking</label>
              <select
                v-model="selectedEdgeType"
                @change="fetchFilteredTiles"
                id="edgeType"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Hoekafwerking kiezen...
                </option>
                <option value="">Alle</option>
                <option v-for="type in edgeTypes" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
            </div>

            <!-- ExecutionType Filter -->
            <div class="mb-1 filter-row">
              <label for="excecutionType" class="form-label">Afwerking</label>
              <select
                v-model="selectedExecutionType"
                @change="fetchFilteredTiles"
                id="excecutionType"
                class="form-select form-select-sm"
              >
                <option value="" disabled selected hidden>
                  Hoekafwerking kiezen...
                </option>
                <option value="">Alle</option>
                <option
                  v-for="type in executionTypes"
                  :key="type"
                  :value="type"
                >
                  {{ type }}
                </option>
              </select>
            </div>

            <!-- Price Filter Slider -->
            <div class="mb-1 filter-row">
              <label for="price-range" class="form-label">Prijs (€)</label>
              <div class="price-slider">
                <!-- <input
                  type="range"
                  :min="priceMin"
                  :max="priceMax"
                  v-model.number="priceRange.min"
                  @input="fetchFilteredTiles"
                  class="form-range"
                /> -->
                <!-- <input
                  type="range"
                  :min="priceMin"
                  :max="priceMax"
                  v-model.number="priceRange.max"
                  @input="fetchFilteredTiles"
                  class="form-range"
                /> -->
              </div>
              <div class="d-flex justify-content-between mt-1">
                min:
                <input
                  type="number"
                  v-model.number="priceRange.min"
                  @input="
                    validatePrice('min');
                    fetchFilteredTiles();
                  "
                  class="form-control form-control-sm"
                  style="width: 60px"
                />
                max:
                <input
                  type="number"
                  v-model.number="priceRange.max"
                  @input="
                    validatePrice('max');
                    fetchFilteredTiles();
                  "
                  class="form-control form-control-sm"
                  style="width: 60px"
                />
              </div>
            </div>

            <!-- Sort Options -->
            <div class="mb-3 filter-row">
              <label for="sort" class="form-label">Sorteer op:</label>
              <select
                v-model="sortOrder"
                @change="fetchFilteredTiles"
                id="sort-by"
                class="form-select form-select-sm"
              >
                <option value="default">Standaard</option>
                <option value="price-asc">Prijs: Laag naar Hoog</option>
                <option value="price-desc">Prijs: Hoog naar Laag</option>
                <option value="name-asc">Naam: A-Z</option>
                <option value="name-desc">Naam: Z-A</option>
              </select>
            </div>

            <!-- Clear Filters Button -->
            <button
              type="button"
              class="btn btn-secondary btn-sm w-100 mb-2"
              @click="clearFilters"
            >
              Filters Wissen
            </button>
          </div>
        </div>
      </div>

      <!-- Products Section -->
      <div class="col-md-9 col-12">
        <div class="product-grid">
          <div v-for="product in tiles" :key="product.id" class="product-card">
            <div class="image-container">
              <img
                :src="
                  true && imageUrls[product.id]
                    ? imageUrls[product.id]
                    : getImage(product.id)
                "
                :alt="product.name || 'Tile image'"
                class="product-image"
                loading="lazy"
              />
            </div>
            <h4 style="font-weight: 600">
              {{ product.name || "Geen naam opgegeven" }}
            </h4>

            <p v-if="product.discount">Korting: {{ product.discount }}%</p>
            <div class="dimensions row">
              <div class="col-6">Kleur:</div>
              <div class="col-6">{{ product.color }}</div>
            </div>
            <div class="dimensions row">
              <div class="col-6">Afmetingen:</div>
              <div class="col-6">
                {{ product.length }}x{{ product.width }} cm
              </div>
            </div>
            <div
              class="dimensions row"
              style="margin-bottom: 0px; text-align: left"
            >
              <div class="col-6">Dikte:</div>
              <div class="col-6">{{ product.thickness }} cm</div>
            </div>
            <p class="price">
              €{{
                product.price
                  ? product.price.toFixed(2)
                  : "Prijs niet beschikbaar"
              }}
              per m²
            </p>
            <button
              @click="viewProductDetails(product)"
              class="btn btn-primary btn-sm"
            >
              Bekijk product
            </button>
          </div>
        </div>

        <!-- Pagination -->
        <nav class="mt-4">
          <ul class="pagination justify-content-center">
            <li
              class="page-item"
              :class="{ disabled: currentPage === 1 }"
              @click="changePage(currentPage - 1)"
            >
              <a class="page-link">Vorige</a>
            </li>
            <li
              class="page-item"
              v-for="page in totalPages"
              :key="page"
              :class="{ active: page === currentPage }"
              @click="changePage(page)"
            >
              <a class="page-link">{{ page }}</a>
            </li>
            <li
              class="page-item"
              :class="{ disabled: currentPage === totalPages }"
              @click="changePage(currentPage + 1)"
            >
              <a class="page-link">Volgende</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import CalculatorComponent from "@/components/CalculatorComponent.vue";

const router = useRouter();
const tiles = ref([]);
const imageUrls = ref({});
const searchQuery = ref("");
const sortOrder = ref("default");
const apiUrl = process.env.VUE_APP_API_URL;
const currentPage = ref(1);
const pageSize = 12;
const totalItems = ref(0);
const totalPages = ref(1);

const brands = ref([]);
const dimensions = ref([]);
const colors = ref([]);
const thicknesses = ref([]);
const materials = ref([]);
const edgeTypes = ref([]);
const applicationTypes = ref([]);
const executionTypes = ref([]);

const isFilterCollapsed = ref(true);
const loading = ref(true); // Added loading state

const selectedBrand = ref("");
const selectedDimensions = ref("");
const selectedColor = ref("");
const selectedThickness = ref("");
const selectedMaterial = ref("");
const selectedEdgeType = ref("");
const selectedApplicationType = ref("");
const selectedExecutionType = ref("");
const priceMin = ref(0);
const priceMax = ref(200);
const priceRange = ref({ min: priceMin.value, max: priceMax.value });

const isSmallScreen = computed(() => window.innerWidth < 768);

// Watchers for price slider constraints
watch(
  () => priceRange.value.min,
  (newMin) => {
    if (newMin >= priceRange.value.max) {
      priceRange.value.max = newMin + 1;
    }
  }
);

watch(
  () => priceRange.value.max,
  (newMax) => {
    if (newMax <= priceRange.value.min) {
      priceRange.value.min = newMax - 1;
    }
  }
);

async function fetchFilteredTiles() {
  loading.value = true; // Start loading
  try {
    const params = {
      name: searchQuery.value || undefined,
      brand: selectedBrand.value || undefined,
      dimensions: selectedDimensions.value || undefined,
      color: selectedColor.value || undefined,
      thickness: selectedThickness.value || undefined,
      material: selectedMaterial.value || undefined,
      minPrice: priceRange.value.min || undefined,
      maxPrice: priceRange.value.max || undefined,
      applicationType: selectedApplicationType.value || undefined,
      edgeType: selectedEdgeType.value || undefined,
      executionType: selectedExecutionType.value || undefined,
      sortBy: getSortField(),
      limit: pageSize,
      offset: (currentPage.value - 1) * pageSize,
    };

    const response = await axios.get(`${apiUrl}/api/tiles/filter`, { params });
    tiles.value = response.data.tiles;
    totalItems.value = response.data.totalItems;
    totalPages.value = Math.ceil(totalItems.value / pageSize);

    // Fetch image URLs after loading products
    tiles.value.forEach((product) => {
      if (true && !imageUrls.value[product.id]) {
        getImageUrl(product.id);
      }
    });
  } catch (error) {
    console.error("Error fetching tiles:", error);
  } finally {
    loading.value = false; // Stop loading
  }
}

async function fetchFilterOptions() {
  try {
    const response = await axios.get(`${apiUrl}/api/tiles/filters`);
    brands.value = response.data.brands || [];
    dimensions.value = response.data.dimensions || [];
    colors.value = response.data.colors || [];
    thicknesses.value = response.data.thicknesses || [];
    materials.value = response.data.materials || [];
    edgeTypes.value = response.data.edgeTypes || [];
    applicationTypes.value = response.data.applicationTypes || [];
    executionTypes.value = response.data.executionTypes || [];
  } catch (error) {
    console.error("Error fetching filter options:", error);
  }
}

function liveSearch() {
  currentPage.value = 1;
  fetchFilteredTiles();
}

function changePage(page) {
  if (page < 1 || page > totalPages.value) return;
  currentPage.value = page;
  fetchFilteredTiles();
}

function getSortField() {
  switch (sortOrder.value) {
    case "price-asc":
      return "price";
    case "price-desc":
      return "price,desc";
    case "name-asc":
      return "name";
    case "name-desc":
      return "name,desc";
    default:
      return "id";
  }
}

function toggleFilterCollapse() {
  isFilterCollapsed.value = !isFilterCollapsed.value;
}

function validatePrice(type) {
  if (type === "min" && priceRange.value.min >= priceRange.value.max) {
    priceRange.value.max = priceRange.value.min + 1;
  } else if (type === "max" && priceRange.value.max <= priceRange.value.min) {
    priceRange.value.min = priceRange.value.max - 1;
  }
}

function clearFilters() {
  searchQuery.value = "";
  selectedBrand.value = "";
  selectedDimensions.value = "";
  selectedColor.value = "";
  selectedThickness.value = "";
  selectedMaterial.value = "";
  priceRange.value = { min: priceMin.value, max: priceMax.value };
  sortOrder.value = "default";
  fetchFilteredTiles();
}

function getImage(tileId) {
  return `${apiUrl}/api/tiles/${tileId}/image`;
}

async function getImageUrl(tileId) {
  try {
    const response = await axios.get(`${apiUrl}/api/tiles/${tileId}/image-url`);
    if (response.data && response.data !== "null") {
      imageUrls.value[tileId] = response.data; // Store the image URL
    }
  } catch (error) {
    console.error("Error fetching image URL:", error);
  }
}

function viewProductDetails(product) {
  router.push({
    path: `/producten/${product.id}`,
  });
}

onMounted(() => {
  fetchFilteredTiles();
  fetchFilterOptions();
});
</script>

<style lang="scss" scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(118, 116, 116, 0.622);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  text-align: center;
}

.spinner i {
  font-size: 3rem;
  color: #6f4a0a;
  margin-bottom: 10px;
}

.spinner p {
  font-size: 1.2rem;
  color: #555;
}

.complete-search-bar {
  display: flex;
  flex-direction: column; /* Stack cart-items vertically */
  align-items: center;
}

.search-bar {
  background-color: #f8f9fa;
  border-radius: 5px;
  width: 80%;
}

/* General styles */
.form-label {
  font-size: 0.85rem;
  white-space: nowrap;
}

.form-control-sm,
.form-select-sm {
  font-size: 0.85rem;
}

.filter-row {
  margin-bottom: 0.75rem;
  text-align: left;
}

.price-slider {
  display: flex;
  gap: 10px;
  align-items: center;
}

.price-slider input[type="range"] {
  flex: 1;
}

.product-page {
  padding: 20px;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  text-align: center;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
}

.image-container {
  width: 100%;
  padding-top: 100%; /* Aspect ratio 1:1 */
  position: relative;
  overflow: hidden;
}

.product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.price {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

.dimensions {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0px;
  text-align: left;
}

.btn {
  background-color: #6f4a0a;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #369f73;
}

.pagination .page-item.active .page-link {
  background-color: grey;
  border-color: black;
}

.pagination .page-link {
  color: black;
}

.card {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-row label {
    margin-bottom: 5px;
  }

  .collapse {
    display: none;
  }

  .collapse.show {
    display: block;
  }

  .search-bar {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .product-grid {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 100%;
  }
}
</style>
