<template>
  <div class="container my-5">
    <!-- Hero sectie met afbeelding links en tekst rechts -->
    <div class="hero-section">
      <div class="hero-image img-1"></div>
      <div class="hero-text">
        <h1>Wandtegels die sfeer en karakter toevoegen</h1>
        <p>
          Wandtegels zijn meer dan alleen een praktische keuze ze geven elke
          ruimte een unieke uitstraling. Of je nu een strakke, moderne look wilt
          of juist een warme, natuurlijke sfeer, bij ons vind je de perfecte
          tegel. Van subtiele tinten tot opvallende patronen, onze wandtegels
          zijn slijtvast, waterbestendig en eenvoudig te onderhouden. Creëer een
          stijlvolle en tijdloze wandafwerking die perfect past bij jouw
          interieur!
        </p>
      </div>
    </div>

    <!-- Bestseller sectie -->
    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Op maat gemaakte natuurstenen wandtegels</h2>
        <p>
          Voor een luxe en exclusieve uitstraling bieden wij natuurstenen
          wandtegels zoals marmer, kwartsiet en andere hoogwaardige
          steensoorten. Perfect voor badkamers, keukens of accentwanden in je
          interieur. Wij snijden elke tegel op maat, zodat je verzekerd bent van
          een perfecte pasvorm en een unieke, tijdloze afwerking. Kies voor de
          natuurlijke schoonheid en duurzaamheid van echte steen!
        </p>
      </div>
      <div class="bestseller-image img-2"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-3"></div>
      <div class="hero-text">
        <h2>Wandtegels met unieke patronen en structuren</h2>
        <p>
          Maak je interieur bijzonder met wandtegels die meer bieden dan een
          effen oppervlak. Kies voor reliëftegels, mozaïek of decoratieve
          patronen om extra diepte en karakter aan je wanden te geven. Of je nu
          een subtiel textuureffect of een opvallend design wilt, wij hebben de
          perfecte tegels om je ruimte een unieke uitstraling te geven!
        </p>
      </div>
    </div>

    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Water- en vlekbestendige wandtegels voor elke ruimte</h2>
        <p>
          Onze wandtegels zijn niet alleen stijlvol, maar ook extreem praktisch.
          Dankzij hun water- en vlekbestendige eigenschappen zijn ze perfect
          voor badkamers, keukens en andere vochtige ruimtes. Geen gedoe met
          schimmel of hardnekkige vlekken gewoon een onderhoudsvriendelijke en
          duurzame wandafwerking die jarenlang mooi blijft!
        </p>
      </div>
      <div class="bestseller-image img-4"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-5"></div>
      <div class="hero-text">
        <h2>Wandtegels als eyecatcher in je interieur</h2>
        <p>
          Wandtegels zijn niet alleen functioneel, maar kunnen ook dé blikvanger
          van je ruimte zijn! Creëer een opvallende accentmuur met unieke
          kleuren, patronen of texturen. Of het nu in de keuken, badkamer of
          woonkamer is, met de juiste wandtegels geef je je interieur een
          stijlvolle en persoonlijke touch!
        </p>
      </div>
    </div>

    <div class="three-column-section scroll-section">
      <!-- Eerste kolom: Inspiratie -->
      <div class="column">
        <h2>Inspiratie nodig?</h2>
        <p>
          Begin je bouw- of renovatieplannen in onze showroom van 600m². Onze
          experten helpen je graag bij het kiezen van de juiste vloer.
        </p>
        <RouterLink class="cta-btn" to="/locatie"
          >Bezoek onze showroom</RouterLink
        >
      </div>

      <!-- Tweede kolom: Advies -->
      <div class="column">
        <h2>Advies nodig?</h2>
        <p>
          Twijfel je over de juiste vloer of tegel? Bel ons, vul ons contact
          formulier in, of kom langs in onze showroom!
        </p>
      </div>

      <!-- Derde kolom: Afbeelding -->
      <div class="column image-column"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const heroSection = document.querySelector(".hero-section");
    const sections = document.querySelectorAll(".scroll-section");

    // Ensure hero section is shown immediately after rendering
    if (heroSection) {
      requestAnimationFrame(() => {
        heroSection.classList.add("show");
      });
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    sections.forEach((section) => observer.observe(section));
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}

.scroll-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.scroll-section.show {
  opacity: 1;
  transform: translateY(0);
}

.container {
  background-color: #f1e9db;
  border-radius: 15px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.3em;
}
.assortment-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
.assortment-item {
  flex: 1 1 250px;
  max-width: 50%;
}
.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  height: 70vh !important;
  display: flex;
  align-items: flex-end;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  transition: transform 0.3s ease-in-out;
  padding: 0;
  text-decoration: none;
  border: 2px solid;
}
.card:hover {
  transform: translateY(-15px);
}
.card-body {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.card-text-container {
  text-align: center;
  width: 100%;
}
.card-title {
  font-weight: bold;
  font-size: 1.8em;
}
.card-text {
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.btn {
  display: block;
  width: fit-content;
  margin: 10px auto;
}

/* Responsiviteit voor tablets */
@media (max-width: 992px) {
  .assortment-item {
    flex: 1 1 calc(50% - 20px); /* Twee kolommen op tablets */
    max-width: calc(50% - 20px);
  }

  .card {
    height: 40vh;
  }
}

/* Responsiviteit voor mobiele schermen */
@media (max-width: 576px) {
  .assortment-item {
    flex: 1 1 90%; /* Eén kolom op mobiele schermen */
    max-width: 90%;
  }

  .card {
    height: 35vh;
  }
}

/* Hero sectie */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;

  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.hero-section.show {
  opacity: 1;
  transform: translateY(0);
}

/* Grote afbeelding aan de linkerkant */
.hero-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Tekst rechts */
.hero-text {
  flex: 1;
  text-align: left;
  padding: 20px;
}

/* Media Queries voor responsiviteit */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    order: 2;
    text-align: center;
  }

  .hero-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

.img-1 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/91807_Imola_-_The_Room_-_Hero.jpg?1728306897")
    no-repeat center center;
}

.img-2 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/91758_Impex__Builds__Eds_-_Porcelain_-_Hero.jpg?1728306879")
    no-repeat center center;
}

.img-3 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/h567-q95/bestanden/artikelen/10/129169_1-00168513_Nature_Gold_Glossy_(23x23)_Moza__ek_30x30_sfeer.jpg?1728567454")
    no-repeat center center;
}

.img-4 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/91772_Sichenia_-_Glam_Project_-_Hero.jpg?1728306883")
    no-repeat center center;
}

.img-5 {
  background: url("https://www.ceratrade.nl/lcms2/RESIZE/q95/bestanden/artikelen/10/17808_Ape_Ceramica_-_Breccia_-_Hero.jpg?1728306910")
    no-repeat center center;
}

/* Bestseller sectie */
.bestseller-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;
}

/* Tekst links */
.bestseller-text {
  flex: 1;
  text-align: right;
  padding: 20px;
}

/* Afbeelding rechts */
.bestseller-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal inhoud */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  position: relative;
}

/* Sluitknop */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Scrollbare container */
.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  white-space: nowrap;
}

.scroll-item {
  flex: 0 0 auto;
  width: 200px;
  text-align: center;
}

.scroll-item img {
  width: 100%;
  border-radius: 5px;
}

/* Responsiviteit */
@media (max-width: 768px) {
  .bestseller-section {
    flex-direction: column;
    text-align: center;
  }

  .bestseller-text {
    order: 2;
    text-align: center;
  }

  .bestseller-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

/* Drie kolommen sectie */
.three-column-section {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

/* Algemene styling voor de kolommen */
.column {
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
/* Rechterkolom met afbeelding */
.image-column {
  background: url("https://www.navarti.com/wp-content/uploads/2023/05/amb-BARROW-Blanco-120x120-1-1184x888.jpg")
    no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  flex: 1;
}
/* Responsiviteit */
@media (max-width: 992px) {
  .three-column-section {
    flex-direction: column;
    text-align: center;
  }

  .image-column {
    height: 250px;
  }
}
</style>
