<template>
  <div class="homepage">
    <!-- ✅ Popup Section -->
    <!-- <div class="popup-overlay" v-if="showPopup">
      <div class="popup-content">
        <img src="@/assets/acties/Openingsactie.jpg" alt="Openingsactie" />

        <div class="button-container">
          <button class="popup-btn popup-btn-close" @click="closePopup">
            Sluiten
          </button>
          <RouterLink to="/producten" class="popup-btn"
            >Bekijk Assortiment</RouterLink
          >
        </div>
      </div>
    </div> -->

    <!-- First Section: Visuals, Headline, Subheadline, and CTA -->
    <section class="header-section">
      <div class="header-content">
        <h1 style="font-size: 2em; font-weight: bold">
          Panjsher Tegels - Vloertegels, Wandtegels en Terrastegels van
          Topkwaliteit
        </h1>

        <i
          ><h3 style="font-weight: normal">
            Bezoek onze 600m² showroom en ontdek topkwaliteit vanaf €19,99 excl.
            BTW!
          </h3></i
        >

        <div class="cta-buttons">
          <RouterLink class="cta-btn" to="/locatie">Showroom</RouterLink>
          <RouterLink class="cta-btn" to="/assortiment">Assortiment</RouterLink>
          <RouterLink class="cta-btn" to="/producten">Producten</RouterLink>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features-section">
      <div class="container">
        <h2 style="font-weight: bold" class="scroll-section">
          Waarom kiezen voor Panjsher Tegelwinkel?
        </h2>
        <div class="features-grid">
          <div class="feature-item scroll-section">
            <i class="fas fa-handshake"></i>
            <h3>Klantvriendelijkheid</h3>
            <p>Wij staan altijd voor u klaar met deskundig advies.</p>
          </div>
          <div class="feature-item scroll-section">
            <i class="fas fa-tags"></i>
            <h3>Prijsklasse voor ieder budget</h3>
            <p>
              Wij bieden tegels voor elke portemonnee zonder in te leveren op
              stijl.
            </p>
          </div>
          <div class="feature-item scroll-section">
            <i class="fas fa-star"></i>
            <h3>Hoogwaardige Kwaliteit</h3>
            <p>Onze tegels zijn geselecteerd op duurzaamheid en esthetiek.</p>
          </div>
          <div class="feature-item scroll-section">
            <i class="fas fa-th-large"></i>
            <h3>Diversiteit aan Stijlen</h3>
            <p>Een ruime keuze aan leveranciers en tegels voor elke stijl.</p>
          </div>
          <div class="feature-item scroll-section">
            <i class="fas fa-truck"></i>
            <h3>Snelle Levering</h3>
            <p>Wij zorgen voor een snelle en betrouwbare levering.</p>
          </div>
          <div class="feature-item scroll-section">
            <i class="fas fa-shield-alt"></i>
            <h3>Garantie</h3>
            <p>Wij bieden uitgebreide garanties voor uw gemoedsrust.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- About Section -->
    <section class="about-section">
      <div class="container scroll-section">
        <h2>Over Ons</h2>
        <p>
          Panjsher Tegelwinkel biedt een breed scala aan
          <strong>hoogwaardige keramische tegels</strong> en
          <strong>duurzame natuurstenen tegels</strong>, geschikt voor vloeren,
          wanden en buitenruimtes. Onze tegels zijn zorgvuldig geselecteerd om
          stijl, duurzaamheid en functionaliteit te garanderen.
        </p>
        <img
          src="../assets/images/ASCOT_VENUS.jpg"
          class="img-fluid"
          alt="Keramische en natuurstenen tegels bij Panjsher Tegelwinkel"
        />
      </div>
    </section>

    <!-- Collaborations Section -->
    <section class="collaborations-section">
      <div class="container">
        <h2 class="scroll-section">Onze Samenwerkingen</h2>
        <div class="brands-grid">
          <div class="brand-item scroll-section">
            <img src="../assets/brand-logos/MARAZZI-LOGO.png" alt="Marazzi" />
          </div>
          <div class="brand-item scroll-section">
            <img src="../assets/brand-logos/mosa-logo.png" alt="Mosa" />
          </div>
          <div class="brand-item scroll-section">
            <img
              src="../assets/brand-logos/ITALSTONE_logo.png"
              alt="Italstone"
            />
          </div>
          <div class="brand-item scroll-section">
            <img
              src="../assets/brand-logos/Excluton-removebg-preview.png"
              alt="Excluton"
            />
          </div>
          <div class="brand-item scroll-section">
            <img
              src="../assets/brand-logos/LOGO_MBI_2015_EN_v1.png"
              alt="MBI"
            />
          </div>
          <a
            style="color: white; font-size: larger; font-weight: bold"
            href="/samenwerkingen"
          >
            <div class="brand-item scroll-section brand-item-hover">
              <div class="cta-buttons">Bekijk alle samenwerkingen</div>
            </div>
          </a>
        </div>
      </div>
    </section>

    <!-- Carousel Section
    <section class="carousel-section">
      <CarouselComponent />
    </section> -->
  </div>
</template>

<script setup>
import { useHead } from "@vueuse/head";
// import CarouselComponent from "@/components/CarouselComponent.vue";
import { ref, onMounted } from "vue";

const showPopup = ref(false);

// ✅ Toon de popup automatisch na 1 seconde wanneer de pagina geladen is
onMounted(() => {
  setTimeout(() => {
    showPopup.value = true;
  }, 1000);

  const headerContent = document.querySelector(".header-content");
  const sections = document.querySelectorAll(".scroll-section");

  // Ensure hero section is shown immediately after rendering
  if (headerContent) {
    requestAnimationFrame(() => {
      headerContent.classList.add("show");
    });
  }

  const observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      threshold: 0.2,
      rootMargin: "0px 0px -100px 0px",
    }
  );

  sections.forEach((section) => observer.observe(section));
});

// const closePopup = () => {
//   showPopup.value = false;
// };

// ✅ SEO + Structured Data Fix
useHead({
  title: "Panjsher Tegels | Hoogwaardige Binnen- en Buitentegels",
  meta: [
    {
      name: "description",
      content:
        "Koop vloertegels, wandtegels en terrastegels bij Panjsher. Wij leveren keramische en natuurstenen tegels in heel België en Nederland!",
    },
    {
      name: "keywords",
      content:
        "tegels kopen, keramische tegels, natuursteen tegels, vloertegels, wandtegels, terrastegels, Belgische tegels, tegels Nederland",
    },
    {
      property: "og:title",
      content: "Panjsher Tegels - Dé specialist in binnen- en buitentegels",
    },
    {
      property: "og:description",
      content:
        "Panjsher biedt een breed assortiment keramische en natuurstenen tegels voor vloeren, wanden en buitenruimtes. Bekijk ons aanbod!",
    },
    {
      property: "og:image",
      content: "https://www.panjsher.be/assets/images/ASCOT_VENUS.jpg",
    },
    {
      property: "og:url",
      content: "https://www.panjsher.be",
    },
  ],
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Store",
        name: "Panjsher Tegels",
        image: "https://www.panjsher.be/assets/images/ASCOT_VENUS.jpg",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Steenweg op Mol 187",
          addressLocality: "Oud-Turnhout",
          addressCountry: "BE",
        },
        description:
          "Panjsher Tegelwinkel biedt een breed assortiment aan keramische en natuurstenen tegels voor binnen en buiten. Bekijk ons assortiment vandaag nog!",
        url: "https://www.panjsher.be",
        priceRange: "$$",
        openingHours: "Mo-Sa 09:00-18:00",
      }),
    },
  ],
});
</script>

<style scoped>
/* General styles for the homepage */
.homepage {
  line-height: 1.6;
}

.scroll-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-out, transform 2.5s ease-out;
  will-change: opacity, transform;
}

.scroll-section.show {
  opacity: 1;
  transform: translateY(0);
}

/* First Section: Header with full-width background image and CTA */
.header-section {
  margin: 0;
  padding: 0;
  background: url("../assets/images/Andaz-Scottsdale.jpg") no-repeat center
    center;
  background-size: cover;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header-section .header-content {
  max-width: 700px;
  padding: 20px;
  background: #f1e9dbd2;
  border-radius: 10px;
  color: #0b3b7a;
}

.header-content {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s ease-out, transform 2.5s ease-out;
  will-change: opacity, transform;
}

.header-content.show {
  opacity: 1;
  transform: translateY(0);
}

.header-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.header-section p {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
}

.cta-btn {
  background-color: #0b3b7a;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid white;
  max-width: 200px;
  margin: 20px auto;
  box-sizing: border-box;
  flex-grow: 1;
}

.cta-btn:hover {
  background-color: black;
}

/* Features Section */
.features-section {
  padding: 50px 20px;
  margin: 50px;
  border-radius: 15px;
  text-align: center;
  background-color: #f1e9db;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.features-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.feature-item {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.feature-item:hover {
  transform: scale(1.02); /* Vergroot het item met 10% */
}

.feature-item i {
  font-size: 2rem;
  color: #1b3976;
  margin-bottom: 10px;
}

.feature-item h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.feature-item p {
  color: #555;
}

/* About Section */
.about-section {
  padding: 50px 20px;
  text-align: center;
  background-color: black;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: white;
}

.about-section p {
  margin-bottom: 30px;
  color: rgb(214, 213, 213);
}

.about-section img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Collaborations Section */
.collaborations-section {
  padding: 50px 20px;
  background-color: #f8f8f8;
  text-align: center;
}

.collaborations-section h2 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.brand-item {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.brand-item:hover {
  transform: scale(1.02); /* Vergroot het item met 10% */
}

.brand-item-hover {
  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
  background-color: grey;
}

.brand-item-hover:hover {
  background-color: black !important;
  transform: scale(1.1);
}

.brand-item img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

/* Media Queries */
@media (max-width: 768px) {
  .brands-grid {
    grid-template-columns: 1fr;
  }

  .brand-item img {
    width: 150px;
    height: 150px;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .header-section {
    height: 50vh;
  }

  .header-section h1 {
    font-size: 2rem;
  }

  .header-section p {
    font-size: 1.2rem;
  }

  .about-section h2 {
    font-size: 2rem;
  }

  .about-section p {
    font-size: 1rem;
  }
}

/* ✅ Popup Overlay - vult het hele scherm */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

/* ✅ Popup Content */
.popup-content {
  position: relative;
  width: 50%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column; /* Zorgt dat de knoppen onder de afbeelding komen */
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  animation: fadeIn 0.5s ease-in-out;
}

/* ✅ Afbeelding volledig zichtbaar */
.popup-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 5px;
}

/* ✅ Knoppen Container */
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

/* ✅ Stijl voor knoppen */
.popup-btn {
  background-color: #1b3976;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.popup-btn-close {
  background-color: #bc0707;
}

.popup-btn:hover {
  background-color: black;
}

/* ✅ Animatie */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* ✅ Responsieve Aanpassingen */
@media (max-width: 768px) {
  .popup-content {
    width: 90%;
  }

  .popup-content img {
    max-height: 70vh;
  }
}

@media (max-width: 480px) {
  .popup-content {
    width: 95%;
  }

  .popup-content img {
    max-height: 60vh;
  }
}
</style>
