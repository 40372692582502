import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import { createWebHistory, createRouter } from "vue-router";

import Home from "./views/HomePage.vue";
import CustomerSupport from "./views/CustomerSupport.vue";
import TransportAndCosts from "./views/TransportAndCosts.vue";
import FloorTiles from "./views/FloorTiles.vue";
import WallTiles from "./views/WallTiles.vue";
import TerraceTiles from "./views/TerraceTiles.vue";
import FacadeTiles from "./views/FacadeTiles.vue";
import RouteAndOpeningHours from "./views/RouteAndOpeningHours.vue";
import CarouselComponent from "./components/CarouselComponent.vue";
import ProductPage from "./views/ProductPage.vue";
import ProductsPage from "./views/ProductsPage.vue";
import QuoteSuccess from "./views/QuoteSuccess.vue";
import ContactSuccess from "./views/ContactSuccess.vue";
import LocationPage from "./views/LocationPage.vue";
import AboutUsPage from "./views/AboutUsPage.vue";
import ContactPage from "./views/ContactPage.vue";
import TermsAndConditionsPage from "./views/TermsAndConditionsPage.vue";
import NotFoundPage from "./views/NotFoundPage.vue";
import CartPage from "./views/CartPage.vue";
import CreateProductPage from "./views/CreateProductPage.vue";
import UpdateProductPage from "./views/UpdateProductPage.vue";
import PrivacyPolicyPage from "./views/PrivacyPolicyPage.vue";
import CollaborationsPage from "./views/CollaborationsPage.vue";
import CollaborationExclutonPage from "./views/CollaborationExclutonPage.vue";
import CollaborationMbiPage from "./views/CollaborationMbiPage.vue";
import CollaborationMosaPage from "./views/CollaborationMosaPage.vue";
import CollaborationMarazziPage from "./views/CollaborationMarazziPage.vue";
import InformationPage from "./views/InformationPage.vue";
import FaqPage from "./views/FaqPage.vue";
import TileInformationPage from "./views/TileInformationPage.vue";
import AssortmentPage from "./views/AssortmentPage.vue";
import CeramicTiles from "./views/CeramicTiles.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/klantenservice", component: CustomerSupport },
  { path: "/transport-en-kosten", component: TransportAndCosts },
  { path: "/vloertegels", component: FloorTiles },
  { path: "/wandtegels", component: WallTiles },
  { path: "/terrastegels", component: TerraceTiles },
  { path: "/geveltegels", component: FacadeTiles },
  { path: "/keramischetegels", component: CeramicTiles },
  { path: "/route-en-openingstijden", component: RouteAndOpeningHours },
  { path: "/producten", component: ProductsPage },
  {
    path: "/producten/:id",
    component: ProductPage,
    props: (route) => ({ id: route.params.id, query: route.query }),
  },
  { path: "/create-product", component: CreateProductPage },
  { path: "/producten/:id/update-product", component: UpdateProductPage },
  { path: "/offerte-succes", component: QuoteSuccess },
  { path: "/contact-succes", component: ContactSuccess },
  { path: "/locatie", component: LocationPage },
  { path: "/over-ons", component: AboutUsPage },
  { path: "/contact", component: ContactPage },
  { path: "/algemene-voorwaarden", component: TermsAndConditionsPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/cart", name: "CartPage", component: CartPage },
  { path: "/samenwerkingen", component: CollaborationsPage },
  { path: "/samenwerkingen/excluton", component: CollaborationExclutonPage },
  { path: "/samenwerkingen/mbi", component: CollaborationMbiPage },
  { path: "/samenwerkingen/mosa", component: CollaborationMosaPage },
  { path: "/samenwerkingen/marazzi", component: CollaborationMarazziPage },
  { path: "/tegels-kopen", component: InformationPage },
  { path: "/informatie-tegels-en-levering", component: TileInformationPage },
  { path: "/veelgestelde-vragen", component: FaqPage },
  { path: "/assortiment", component: AssortmentPage },
  // Other routes go here...
  {
    path: "/:catchAll(.*)", // Catch all undefined routes
    name: "NotFoundPage",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(), // Changed from createMemoryHistory() to createWebHistory()
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }; // Scroll to the top of the page
    }
  },
});

const app = createApp(App).use(router);

app.component("CarouselComponent", CarouselComponent);

app.mount("#app");
