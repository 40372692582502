<template>
  <div class="faq-page">
    <h1>Veelgestelde Vragen (FAQ)</h1>

    <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
      <button class="faq-question" @click="toggleAnswer(index)">
        {{ faq.question }}
        <span class="arrow" :class="{ open: faq.open }">&#9660;</span>
      </button>

      <div v-if="faq.open" class="faq-answer">
        <p>{{ faq.answer }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const faqs = ref([
  {
    question: "Hoe kan ik tegels bestellen?",
    answer:
      "U kunt tegels eenvoudig bestellen via onze website met behulp van een offerte of door contact met ons op te nemen.",
    open: false,
  },
  {
    question: "Wat is de levertijd van de tegels?",
    answer:
      "De levertijd varieert tussen 7-14 werkdagen, afhankelijk van uw locatie.",
    open: false,
  },
  {
    question: "Bieden jullie ook bezorgservice aan?",
    answer:
      "Ja, wij bieden een snelle en betrouwbare bezorgservice in heel België en Nederland.",
    open: false,
  },
  {
    question: "Hoe bereken ik hoeveel tegels ik nodig heb?",
    answer:
      "Gebruik onze tegelcalculator op de productpagina om het benodigde aantal tegels te berekenen, inclusief snijverlies.",
    open: false,
  },
]);

const toggleAnswer = (index) => {
  faqs.value[index].open = !faqs.value[index].open;
};
</script>

<style scoped>
.faq-page {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #1b3976;
  margin-bottom: 20px;
}

.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.faq-question {
  background: none;
  border: none;
  color: #1b3976;
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: color 0.3s ease;
}

.faq-question:hover {
  color: #000;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 10px 15px;
  background: #fff;
  border-radius: 5px;
  margin-top: 5px;
  color: #333;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .faq-page {
    padding: 10px;
  }

  .faq-question {
    font-size: 1rem;
  }
}
</style>
