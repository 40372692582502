<template>
  <div class="container my-5">
    <!-- Hero sectie met afbeelding links en tekst rechts -->
    <div class="hero-section">
      <div class="hero-image img-1"></div>
      <div class="hero-text">
        <h1>Duurzame terrastegels voor een stijlvolle buitenruimte</h1>
        <p>
          Maak van je terras een prachtige en onderhoudsvriendelijke plek met de
          terrastegels van Panjsher B.V.. Van robuuste natuursteen tot moderne
          keramische tegels, wij hebben de perfecte oplossing voor elke tuin.
          Weerbestendig, slijtvast en in diverse formaten verkrijgbaar. Kies
          jouw stijl en geniet jarenlang van een strak en sfeervol terras!
        </p>
      </div>
    </div>

    <!-- Bestseller sectie -->
    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Innovatieve terrastegels met de nieuwste technologie</h2>
        <p>
          Wij bieden de nieuwste generatie terrastegels, waarbij beton en
          keramiek worden gecombineerd voor de ultieme balans tussen kracht en
          stijl. De robuuste betonnen onderlaag zorgt voor stabiliteit en een
          sterke basis, terwijl de keramische toplaag een luxe uitstraling en
          slijtvastheid biedt. Deze tegels zijn weerbestendig, kleurvast en
          onderhoudsvriendelijk ideaal voor een duurzaam en stijlvol terras!
        </p>
      </div>
      <div class="bestseller-image img-2"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-3"></div>
      <div class="hero-text">
        <h2>Duurzame tegels met oog voor het milieu</h2>
        <p>
          Bij ons vind je terrastegels die niet alleen stijlvol en sterk zijn,
          maar ook beter voor het milieu. Door innovatieve productiemethoden en
          duurzame materialen verminderen we de impact op de natuur. Onze
          terrastegels zijn gemaakt om lang mee te gaan, slijtvast en
          recyclebaar, waardoor ze een bewuste keuze zijn voor elke ruimte. Kies
          voor kwaliteit én duurzaamheid!
        </p>
      </div>
    </div>

    <div class="bestseller-section scroll-section mt-5">
      <div class="bestseller-text">
        <h2>Terrastegels met extra veiligheid en onderhoudsgemak</h2>
        <p>
          Onze terrastegels zijn ontworpen voor maximale veiligheid en comfort.
          Dankzij een speciale afwerking zijn ze vuilwerend en bestand tegen mos
          en algen, waardoor ze altijd schoon en onderhoudsvriendelijk blijven.
          De antislipstructuur voorkomt uitglijden, zelfs bij nat weer, en maakt
          ze ideaal voor gezinnen met kinderen. Daarnaast zijn ze lichtgewicht,
          vorstbestendig en duurzaam, perfect voor een mooi en veilig terras het
          hele jaar door!
        </p>
      </div>
      <div class="bestseller-image img-4"></div>
    </div>

    <div class="hero-section scroll-section">
      <div class="hero-image img-5"></div>
      <div class="hero-text">
        <h2>Kleurechte terrastegels die jarenlang mooi blijven</h2>
        <p>
          Onze terrastegels zijn niet alleen stijlvol, maar ook kleurecht.
          Dankzij een speciale productiemethode blijven de kleuren levendig,
          zelfs na jaren van blootstelling aan zon, regen en vorst. Geen
          vervaging, geen verkleuring alleen een tijdloos mooi terras waar je
          jarenlang van geniet!
        </p>
      </div>
    </div>

    <div class="three-column-section scroll-section">
      <!-- Eerste kolom: Inspiratie -->
      <div class="column">
        <h2>Inspiratie nodig?</h2>
        <p>
          Begin je bouw- of renovatieplannen in onze showroom van 600m². Onze
          experten helpen je graag bij het kiezen van de juiste vloer.
        </p>
        <RouterLink class="cta-btn" to="/locatie"
          >Bezoek onze showroom</RouterLink
        >
      </div>

      <!-- Tweede kolom: Advies -->
      <div class="column">
        <h2>Advies nodig?</h2>
        <p>
          Twijfel je over de juiste vloer of tegel? Bel ons, vul ons contact
          formulier in, of kom langs in onze showroom!
        </p>
      </div>

      <!-- Derde kolom: Afbeelding -->
      <div class="column image-column"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const heroSection = document.querySelector(".hero-section");
    const sections = document.querySelectorAll(".scroll-section");

    // Ensure hero section is shown immediately after rendering
    if (heroSection) {
      requestAnimationFrame(() => {
        heroSection.classList.add("show");
      });
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    sections.forEach((section) => observer.observe(section));
  },
};
</script>

<style scoped>
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2em;
}

.scroll-section {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.scroll-section.show {
  opacity: 1;
  transform: translateY(0);
}

.container {
  background-color: #f1e9db;
  border-radius: 15px;
  padding: 20px;
  margin: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 1.3em;
}
.assortment-grid {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
.assortment-item {
  flex: 1 1 250px;
  max-width: 50%;
}
.card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  height: 70vh !important;
  display: flex;
  align-items: flex-end;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
  transition: transform 0.3s ease-in-out;
  padding: 0;
  text-decoration: none;
  border: 2px solid;
}
.card:hover {
  transform: translateY(-15px);
}
.card-body {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.card-text-container {
  text-align: center;
  width: 100%;
}
.card-title {
  font-weight: bold;
  font-size: 1.8em;
}
.card-text {
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.9);
}
.btn {
  display: block;
  width: fit-content;
  margin: 10px auto;
}

/* Responsiviteit voor tablets */
@media (max-width: 992px) {
  .assortment-item {
    flex: 1 1 calc(50% - 20px); /* Twee kolommen op tablets */
    max-width: calc(50% - 20px);
  }

  .card {
    height: 40vh;
  }
}

/* Responsiviteit voor mobiele schermen */
@media (max-width: 576px) {
  .assortment-item {
    flex: 1 1 90%; /* Eén kolom op mobiele schermen */
    max-width: 90%;
  }

  .card {
    height: 35vh;
  }
}

/* Hero sectie */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;

  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.hero-section.show {
  opacity: 1;
  transform: translateY(0);
}

/* Grote afbeelding aan de linkerkant */
.hero-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Tekst rechts */
.hero-text {
  flex: 1;
  text-align: left;
  padding: 20px;
}

/* Media Queries voor responsiviteit */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    order: 2;
    text-align: center;
  }

  .hero-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

.img-1 {
  background: url("https://excluton.nl/img/gardens/1675955882.jpg") no-repeat
    center center;
}

.img-2 {
  background: url("https://excluton.nl/img/gardens/1625493365.jpg") no-repeat
    center center;
}

.img-3 {
  background: url("https://excluton.nl/img/gardens/1625493314.jpg") no-repeat
    center center;
}

.img-4 {
  background: url("https://www.mbimedia.nl/wp-content/uploads/wp_photo_seller/108/watermark_GeoCeramica-2Drive-Negro-Puro06.jpg")
    no-repeat center center;
}

.img-5 {
  background: url("https://www.mbi.nl/lcms2/RESIZE/q95/bestanden/afbeeldingen/834/GeoCeramica-sierbestrating-Copper-Steel_(1).jpg?1725892139")
    no-repeat center center;
}

/* Bestseller sectie */
.bestseller-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;
}

/* Tekst links */
.bestseller-text {
  flex: 1;
  text-align: right;
  padding: 20px;
}

/* Afbeelding rechts */
.bestseller-image {
  flex: 1;
  background-size: cover;
  height: 400px;
  border-radius: 10px;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal inhoud */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  position: relative;
}

/* Sluitknop */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Scrollbare container */
.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  white-space: nowrap;
}

.scroll-item {
  flex: 0 0 auto;
  width: 200px;
  text-align: center;
}

.scroll-item img {
  width: 100%;
  border-radius: 5px;
}

/* Responsiviteit */
@media (max-width: 768px) {
  .bestseller-section {
    flex-direction: column;
    text-align: center;
  }

  .bestseller-text {
    order: 2;
    text-align: center;
  }

  .bestseller-image {
    order: 1;
    width: 100%;
    height: 250px;
  }
}

/* Drie kolommen sectie */
.three-column-section {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: stretch;
}

/* Algemene styling voor de kolommen */
.column {
  flex: 1;
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
/* Rechterkolom met afbeelding */
.image-column {
  background: url("https://www.navarti.com/wp-content/uploads/2023/05/amb-BARROW-Blanco-120x120-1-1184x888.jpg")
    no-repeat center center;
  background-size: cover;
  border-radius: 10px;
  flex: 1;
}
/* Responsiviteit */
@media (max-width: 992px) {
  .three-column-section {
    flex-direction: column;
    text-align: center;
  }

  .image-column {
    height: 250px;
  }
}
</style>
